import React, { useContext } from "react";
import Navbar from "../Navbar";
import CTD from "../CallToDownload";
import "./style.scss";
import { GeneralContext } from "../../../contexts/General";

const Menu = () => {
  const { setIsMenuOpen } = useContext(GeneralContext);

  return (
    <div className="menu">
      <div className="menu-header">
        <img
          onClick={() => setIsMenuOpen(false)}
          className="menu-close-btn"
          src={require("../../../assets/close.svg").default}
          alt=""
        />
        {/* <h2 className='menu-label'>Menu</h2> */}
      </div>

      <div className="menu-nav">
        <Navbar />
      </div>

      <div className="menu-download">
        <CTD />
      </div>

      <div className="content">
        <div className="menu-block">
          <h2>L'entreprise</h2>
          <ul>
            <li>
              <a href="/about">Qui sommes-nous ?</a>{" "}
            </li>
            <li>
              <a href="/contact">Contactez nous</a>{" "}
            </li>
            <li>
              <span href="#" id="offre-emploies">
                Offres D'emploi <span id="bientot">bientôt</span>{" "}
              </span>{" "}
            </li>
          </ul>
        </div>

        <div className="menu-block">
          <h2>Ressources</h2>
          <ul>
            <li>
              <a href="/terms">Termes et Conditions</a>
            </li>
            <li>
              <a href="/policy">Protection des données</a>
            </li>
          </ul>
        </div>

        <div className="menu-block">
          <h2>A Découvrir</h2>
          <ul>
            <li>
              <span id="nos-services-menu">
                Nos Offres <span id="bientot">bientôt</span>{" "}
              </span>{" "}
            </li>
          </ul>
        </div>

        <div className="menu-block">
          <h2>Contact</h2>
          <ul>
            {/* <li>Contact@mooshir.com</li> */}
            <li className="localisation">
              Lotissement girot N145 - Chéraga,Alger
            </li>
            <li>(+213) 0553 132 156</li>
          </ul>
        </div>
      </div>

      <div className="menu-social">
        <img
          src={require("../../../assets/Mooshir.svg").default}
          alt="social"
        />
        <div className="socials">
          <h3>Suivez-nous sur </h3>
          <div className="footer-social">
            <div className="social">
              <a
                href="https://www.facebook.com/profile.php?id=100093059734137"
                target="_blank"
              >
                <img
                  src={require("../../../assets/fb.svg").default}
                  alt="social"
                />
              </a>
            </div>
            <div className="social">
              <a
                href="https://www.instagram.com/mooshir_immobilier/"
                target="_blank"
              >
                <img
                  src={require("../../../assets/instagram.svg").default}
                  alt="social"
                />
              </a>
            </div>
            <div className="social">
              <a
                href="https://www.linkedin.com/company/mooshiralgerie/about/"
                target="_blank"
              >
                <img
                  src={require("../../../assets/linkedin.svg").default}
                  alt="social"
                />
              </a>
            </div>
            <div className="social">
              <a
                href="https://www.youtube.com/channel/UCqdalZ1R2ARd2aSgEYSJGaw"
                target="_blank"
              >
                <img
                  src={require("../../../assets/youtube.svg").default}
                  alt="social"
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      <img className="Menu-bg" src={require("../../../assets/M.png")} alt="M" />
    </div>
  );
};

export default Menu;
