import React from "react";
import "./style.scss";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import AppFooter from "../../components/AppFooter";
// import usePixelFacebook from "../../Utils/Hooks/usePixelFacebook";

const Copyrights = () => {
  // usePixelFacebook();
  return (
    <div className="copyrights">
      <Navbar />
      <div className="copyright-text">
        <h2>Terme et Condition</h2>
        <h3>MOOSHIR PLATFORM TERMS & CONDITIONS</h3>
        <h4>
          {" "}
          <span>General</span>{" "}
        </h4>
        <h4>Thank you for using MOOSHIR!</h4>
        <p>
          Please read these terms and conditions (the “Terms”) carefully as they
          contain important information concerning your legal rights, remedies
          and obligations. These Terms include various limitations and
          exclusions around our liability, which you should pay particular
          attention to. When these Terms mention “MOOSHIR,” “we”, “us”, or
          “our,” they refer to MOOSHIR Technologies Ltd and its subsidiaries,
          employees, officers, agents or affiliates. Mooshir Technologies Ltd.
          is a company incorporated under Laws of ALGERIA (“ALG”), with place of
          business at lotissement girou cheraga, alger with the Tax Registration
          Number: [100567615800003]. When these Terms mention “User”, “you” or
          “your” they refer to any person who uses the Platform, either in an
          individual capacity or on behalf of legal entity, including Tenants
          and Landlords (as defined below). These Terms constitute a legally
          binding agreement between you and Urban and apply to your access to
          and use of the Platform (as defined below). By accessing and using the
          Platform, you agree that you will comply with and be bound by these
          Terms. If you do not agree with any part of these Terms, you should
          not access or use the Platform or any of the Services. Urban reserves
          the right to modify these Terms at any time. Any changes made to these
          Terms that affect your rights will be communicated to you via email
          (where you are a registered User), as well as being updated on the
          Platform. By continuing to use the Platform following any changes to
          the Terms, you agree to continue to be bound by them. By using the
          Platform, you accept the responsibility to frequently review these
          Terms so that you remain aware of your legal rights and obligations
          contained within them.
        </p>
      </div>

      <div className="desk-footer">
        <Footer />
      </div>

      <div className="mobile-footer">
        <AppFooter />
      </div>
    </div>
  );
};

export default Copyrights;
