import React from "react";
import "./style.scss";
import { CustomEventGA } from "../../Utils/CustomEventGA";
const CTD = (props) => (
  <div className="download">
    <a
      className="app-store-link"
      href="https://linktr.ee/mooshir"
      target="_blank"
    >
      <button
        onClick={() => {
          CustomEventGA({
            cat: "click-button",
            action: `download-app-button-link-tree-${props.parent}`,
            label: `download-app-button-link-tree-${props.parent}`,
          });
        }}
      >
        <span id="telecharger">Téléchargez L'application</span>
        <img src={require("../../../assets/download.svg").default} alt="icon" />
      </button>
    </a>
    <div className="stores">
      <a
        className="app-store-link"
        href="https://play.google.com/store/apps/details?id=com.mooshir"
        target="_blank"
      >
        <button
          onClick={() => {
            CustomEventGA({
              cat: "click-button",
              action: `google-download-button-${props.parent}`,
              label: `google-download-button-${props.parent}`,
            });
          }}
        >
          <img
            src={require("../../../assets/download-gp.svg").default}
            alt="icon"
          />
        </button>
      </a>
      <a
        className="app-store-link"
        href="https://apps.apple.com/app/mooshir-immobilier-services/id6453938369"
        target="_blank"
      >
        <button
          onClick={() => {
            CustomEventGA({
              cat: "click-button",
              action: `ios-download-button-${props.parent}`,
              label: `ios-download-button-${props.parent}`,
            });
          }}
        >
          <img
            src={require("../../../assets/download-apple.svg").default}
            alt="icon"
          />
        </button>
      </a>
      <a
        className="app-store-link"
        href="https://appgallery.huawei.com/app/C108820629"
        target="_blank"
      >
        <button
          onClick={() => {
            CustomEventGA({
              cat: "click-button",
              action: `huawei-download-button-${props.parent}`,
              label: `huawei-download-button-${props.parent}`,
            });
          }}
        >
          <img
            src={require("../../../assets/download-huawei.svg").default}
            alt="icon"
          />
        </button>
      </a>
    </div>
  </div>
);

export default CTD;
