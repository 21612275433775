import React, { useState } from "react";
import "./style.scss";

const Select = ({ name, options, placeholder, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };
  return (
    <div className="select-container">
      <div className={`custom-select ${isOpen ? "open" : ""}`}>
        <div className="select-trigger" onClick={toggleDropdown}>
          <span>{selectedOption ? selectedOption : placeholder}</span>
          <img
            alt="chevron"
            src={require("../../../assets/chevron.svg").default}
            className="fas fa-chevron-down"
          />
        </div>
        <ul className="options">
          {Object.keys(options)?.map((option) => {
            return (
              <li
                onClick={() => {
                  selectOption(options[option].value);
                  if (onSelect) {
                    onSelect(name, options[option].label);
                  }
                }}
              >
                {options[option].value}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Select;
