import React from "react";
import "./style.scss";
import Navbar from "../../components/Navbar";
import CTD from "../../components/CallToDownload";
const Hero = () => {
  return (
    <div className="hero">
      <Navbar />

      <div className="mockup-hero">
        <div className="mockup-hero-mobile">
          <div className="mobile-text">
            <h2>IMMOBILIER et SERVICES</h2>
            <h3>en une seule APPLICATION !</h3>
          </div>
          <img
            alt="mockup"
            className="hero-mockup-image-mobile"
            src={require("../../../assets/Phone-Mockup.webp")}
          />
          <>
            <CTD parent={"ctd-hero"} />
          </>
        </div>
        <img
          alt="mockup"
          className="hero-mockup-image"
          src={require("../../../assets/mockup-hero.webp")}
        />

        <img
          alt="mockup"
          className="hero-mockup-background"
          src={require("../../../assets/background-phone.webp")}
        />
      </div>

      <div className="hero-text-desktop">
        <h1>IMMOBILIER et SERVICES</h1>
        <h2>en une seule APPLICATION !</h2>
      </div>
    </div>
  );
};

export default Hero;
