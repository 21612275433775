import React from "react";
import "./style.scss";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import AppFooter from "../../components/AppFooter";
// import usePixelFacebook from "../../Utils/Hooks/usePixelFacebook";

const About = () => {
  // usePixelFacebook();
  return (
    <div className="about">
      <Navbar />
      <div className="about-text">
        <h2>Qui sommes-nous ?</h2>
        <div>
          <p>
            MOOSHIR est une application dédiée aux annonces immobilières et aux
            annonces des services de l'habitat, destinée aux marchés B2B et B2C,
            disponible sur iOS et Android.
          </p>
          <p>
            Conçue pour simplifier et faciliter les recherches et les
            transactions immobilières pour les professionnels et les
            particuliers et regrouper les différents services de l'habitat.
          </p>
          <p>
            En tirant parti des dernières technologies et des principes de
            conception centrés sur l'utilisateur, nous offrons une expérience
            intuitive et transparente à nos utilisateurs.
          </p>
          <p>
            À travers notre application nous croyons que trouver son bien
            immobilier ne devrait plus être difficile.
          </p>
        </div>
      </div>

      <div className="desk-footer">
        <Footer />
      </div>

      <div className="mobile-footer">
        <AppFooter containerColor="#EAF7FF" />
      </div>
    </div>
  );
};

export default About;
