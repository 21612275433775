import React, { useContext } from "react";
import "./style.scss";
import { GeneralContext } from "../../../contexts/General";
import { CustomEventGA } from "../CustomEventGA";
const DownloadModal = () => {
  const { setIsModal } = useContext(GeneralContext);
  return (
    <div className="modal-wrapper">
      <div className="download-modal">
        <div className="modal-header">
          <button onClick={() => setIsModal(false)}>
            <img
              fill="white"
              src={require("../../../assets/close-white.svg").default}
              alt="close"
            />
          </button>
        </div>

        <div className="modal-body">
          <img
            className="qr-code"
            src={require("../../../assets/qr-code.webp")}
            alt="icon"
          />
          <h2>Téléchargez L'application Gratuitement</h2>
          <p>Scannez le code QR avec l'appareil photo</p>
          <div className="buttons">
            <div className="buttons-grid">
              <a
                className="app-store-link"
                href="https://play.google.com/store/apps/details?id=com.mooshir"
                target="_blank"
              >
                <button
                  onClick={() => {
                    CustomEventGA({
                      cat: "click-button",
                      action: "google-download-button-modal",
                      label: "google-download-button-modal",
                    });
                  }}
                >
                  <img
                    src={require("../../../assets/playstore.svg").default}
                    alt="apple"
                  />
                  <div className="text">
                    <span>Download it on</span>
                    <p>Google play</p>
                  </div>
                </button>
              </a>
              <a
                className="app-store-link"
                href="https://apps.apple.com/app/mooshir-immobilier-services/id6453938369"
                target="_blank"
              >
                <button
                  onClick={() => {
                    CustomEventGA({
                      cat: "click-button",
                      action: "ios-download-button-modal",
                      label: "ios-download-button-modal",
                    });
                  }}
                >
                  <img
                    src={require("../../../assets/apple.svg").default}
                    alt="apple"
                  />
                  <div className="text">
                    <span>Download it on</span>
                    <p>Apple store</p>
                  </div>
                </button>
              </a>
              <a
                className="app-store-link"
                href="https://appgallery.huawei.com/app/C108820629"
                target="_blank"
              >
                <button
                  onClick={() => {
                    CustomEventGA({
                      cat: "click-button",
                      action: "huawei-download-button-modal",
                      label: "huawei-download-button-modal",
                    });
                  }}
                >
                  <img
                    src={require("../../../assets/huawei.svg").default}
                    alt="apple"
                  />
                  <div className="text">
                    <span>Explore it on</span>
                    <p>App Gallery</p>
                  </div>
                </button>
              </a>
            </div>
          </div>
          <img
            className="mooshir-app-img"
            src={require("../../../assets/download-modal-app.webp")}
            alt="icon"
          />
        </div>
      </div>
    </div>
  );
};

export default DownloadModal;
