import React from "react";
import "./style.scss";
import { CustomEventGA } from "../../Utils/CustomEventGA";
const Footer = ({ backgroundColor, home = true }) => {
  return (
    <footer className="footer" style={{ backgroundColor: backgroundColor }}>
      <div className="content">
        <div className="footer-up">
          <div className="footer-logo">
            <img
              src={require("../../../assets/Mooshir-text.svg").default}
              alt="text-logo"
            />
          </div>
          {home ? (
            <>
              <div className="footer-list">
                <h2>L'entreprise</h2>
                <ul>
                  <li>
                    <a href="/about">Qui sommes-nous ?</a>
                  </li>
                  <li>
                    <a href="/contact">Contactez nous</a>
                  </li>
                  <li>
                    <a id="offre-emploies">
                      Offres d'emploi <span id="bientot">bientôt</span>{" "}
                    </a>
                  </li>
                </ul>
              </div>
              <div className="footer-list">
                <h2>Ressources</h2>
                <ul>
                  <li>
                    <a href="/terms">Termes et Conditions</a>
                  </li>
                  <li>
                    <a href="/policy">Protection des données</a>
                  </li>
                </ul>
              </div>

              <div className="footer-list">
                <h2>A Découvrir</h2>
                <ul>
                  <li>
                    <a
                      id="nos-services-footer"
                      // href="https://play.google.com/store/apps/details?id=com.mooshir"
                    >
                      Nos Offres <span id="bientot">bientôt</span>{" "}
                    </a>
                  </li>
                </ul>
              </div>
            </>
          ) : null}
          <div className="footer-apps">
            <div className="available-store">
              <a
                className="app-store-link"
                href="https://play.google.com/store/apps/details?id=com.mooshir"
                target="_blank"
              >
                <div
                  onClick={() => {
                    CustomEventGA({
                      cat: "click-button",
                      action: "google-download-button-footer",
                      label: "google-download-button-footer",
                    });
                  }}
                  className="app-store"
                >
                  <img
                    src={require("../../../assets/playstore.svg").default}
                    alt="app"
                  />
                  <div className="text">
                    <p>Get it on</p>
                    <h4>Google Play</h4>
                  </div>
                </div>
              </a>
              <a
                className="app-store-link"
                href="https://apps.apple.com/app/mooshir-immobilier-services/id6453938369"
                target="_blank"
              >
                <div
                  onClick={() => {
                    CustomEventGA({
                      cat: "click-button",
                      action: "ios-download-button-footer",
                      label: "ios-download-button-footer",
                    });
                  }}
                  className="app-store"
                >
                  <img
                    src={require("../../../assets/apple.svg").default}
                    alt="app"
                  />
                  <div className="text">
                    <p>Download on the</p>
                    <h4>App store</h4>
                  </div>
                </div>
              </a>
              <a
                className="app-store-link"
                href="https://appgallery.huawei.com/app/C108820629"
                target="_blank"
              >
                <div
                  onClick={() => {
                    CustomEventGA({
                      cat: "click-button",
                      action: "huawei-download-button-footer",
                      label: "huawei-download-button-footer",
                    });
                  }}
                  className="app-store"
                >
                  <img
                    src={require("../../../assets/huawei.svg").default}
                    alt="app"
                  />
                  <div className="text">
                    <p>Explore it on</p>
                    <h4>AppGallery</h4>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>

        <div className="footer-copyright">
          <p>
            Copyright © 2024 Mooshir, Tous les droits sont réservés{" "}
            <span>
              {" "}
              <a target="_blank" href="https://fenovation.com">
                {" "}
                <b>Fenovation</b>{" "}
              </a>
            </span>
          </p>
          <div className="copyright-socials">
            <h3>Suivez nous</h3>
            <div className="footer-social">
              <div
                onClick={() => {
                  CustomEventGA({
                    cat: "click-button",
                    action: "facebook-social-button-footer",
                    label: "facebook-social-button-footer",
                  });
                }}
                className="social"
              >
                <a
                  href="https://www.facebook.com/profile.php?id=100093059734137"
                  target="_blank"
                >
                  <img
                    src={require("../../../assets/fb.svg").default}
                    alt="social"
                  />
                </a>
              </div>
              <div
                onClick={() => {
                  CustomEventGA({
                    cat: "click-button",
                    action: "instagram-social-button-footer",
                    label: "instagram-social-button-footer",
                  });
                }}
                className="social"
              >
                <a
                  href="https://www.instagram.com/mooshir_immobilier/"
                  target="_blank"
                >
                  <img
                    src={require("../../../assets/instagram.svg").default}
                    alt="social"
                  />
                </a>
              </div>
              <div
                onClick={() => {
                  CustomEventGA({
                    cat: "click-button",
                    action: "linkedin-social-button-footer",
                    label: "linkedin-social-button-footer",
                  });
                }}
                className="social"
              >
                <a
                  href="https://www.linkedin.com/company/mooshiralgerie/about/"
                  target="_blank"
                >
                  <img
                    src={require("../../../assets/linkedin.svg").default}
                    alt="social"
                  />
                </a>
              </div>
              <div
                onClick={() => {
                  CustomEventGA({
                    cat: "click-button",
                    action: "youtube-social-button-footer",
                    label: "youtube-social-button-footer",
                  });
                }}
                className="social"
              >
                <a
                  href="https://www.youtube.com/channel/UCqdalZ1R2ARd2aSgEYSJGaw"
                  target="_blank"
                >
                  <img
                    src={require("../../../assets/youtube.svg").default}
                    alt="social"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
