import React from "react";
import "./style.scss";
import AOS from "aos";
import "aos/dist/aos.css";
const Services = () => {
  React.useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 90,
    });
  }, []);
  return (
    <div className="services">
      <div className="title">
        <p>
          C'est devenu <span>plus facile </span> de trouver
        </p>
        <p>
          {" "}
          <span> un bien </span> ou un <span> service </span> avec{" "}
          <b>Mooshir</b>{" "}
        </p>
      </div>

      <div className="images">
        <img
          data-aos="fade-right"
          className="image-left"
          src={require("../../../assets/service-2.webp")}
          alt="service"
        />

        <img
          className="image-center"
          src={require("../../../assets/maqam.webp")}
          alt="service"
        />

        <img
          data-aos="fade-left"
          className="image-right"
          src={require("../../../assets/service-1.webp")}
          alt="service"
        />

        <img
          className="mobile-image"
          src={require("../../../assets/service-4.webp")}
          alt="service"
        />
      </div>
    </div>
  );
};

export default Services;
