import React from "react";
import ReactDOM from "react-dom/client";
import Mooshir from "./Mooshir";
import "./index.scss";
import { BrowserRouter } from "react-router-dom";
import { GeneralContextProvider } from "./contexts/General";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <GeneralContextProvider>
        <Mooshir />
      </GeneralContextProvider>
    </BrowserRouter>
  </React.StrictMode>
);
