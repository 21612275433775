import React, { useState, useRef, useEffect } from "react";
import "./style.scss";
// import Grabbing from "../../components/Grabbing";
// import { motion } from "framer-motion";

import { motion, useScroll, useSpring, useTransform } from "framer-motion";
import useWindowDimensions from "../../Utils/getWindowDimensions";

function useParallax(value, distance) {
  return useTransform(value, [0, 1], [-distance, distance]);
}

const Design = () => {
  const { height, width } = useWindowDimensions();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (width < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  function Image({ item, index }) {
    const ref = useRef();
    const variable = isMobile ? ["start end", "end end"] : undefined;
    const { scrollYProgress } = useScroll({
      target: ref,
      offset: variable,
    });

    const y = useParallax(scrollYProgress, 300);
    const Description = () => {
      return item.description;
    };
    return (
      <li class="card">
        <div
          class="card-body"
          style={{
            backgroundColor: item.color,
            flexDirection: index % 2 == 0 ? "row-reverse" : "row",
          }}
        >
          <motion.div className="image-right-section" ref={ref}>
            <img
              src={require(`../../../assets/mockup/${item.image}`)}
              alt="A London skyscraper"
              className="img-mockup"
            />
          </motion.div>
          <motion.div style={{}} className="image-left-section">
            <motion.div className="carousel-button">
              <img
                className="image-carousel-button"
                src={require(`../../../assets/${item.btnImage}`)}
                alt=""
              />

              <motion.h2>{item.label}</motion.h2>
            </motion.div>
            <Description />
          </motion.div>
        </div>
      </li>
    );
  }

  const images = [
    {
      label: "Immobilier",
      color: "#BAF8F0",
      description: (
        <>
          <p>
            <span>Achetez, Louez Et Cherchez</span> sans limites ....
          </p>
        </>
      ),

      image: "immobilier.png",
      btnImage: "home.svg",
      fade: "fade-right",
    },
    {
      label: "Services",
      color: "#ff966e",

      description: (
        <>
          <p>
            <span>Ajoutez</span> et mettez vos services <span> en valeur.</span>
          </p>
        </>
      ),
      image: "service.png",
      btnImage: "services.svg",
      fade: "fade-left",
    },
    {
      label: "Annonces",
      color: "#BAF8F0",

      description: (
        <>
          <p>
            Ajouter des annonces
            <span> sans limites</span> et en <span> quelques étapes.</span>
          </p>
        </>
      ),
      image: "annonce.png",
      btnImage: "plus.svg",
      fade: "fade-right",
    },
    {
      label: "Map",
      color: "#85ccfa",
      description: (
        <>
          <p>
            Trouvez des biens
            <span> Rapidement et facilement</span> avec l'expérience
            <span> Map.</span>
          </p>
        </>
      ),
      image: "map.png",
      btnImage: "map.svg",
      fade: "fade-down",
    },
    {
      label: "Vidéo",
      color: "#ffa6b9",

      description: (
        <>
          <p>
            <span>Explorer</span> pleins de vidéos
            <span> d'immobilier et de services.</span>
          </p>
        </>
      ),
      image: "video.png",
      btnImage: "video.svg",
      fade: "fade-right",
    },
    {
      label: "Chat",
      color: "#c3c1ff",

      description: (
        <>
          <p>
            <span>Discuter</span> avec les annonceurs
            <span> sans limites.</span>
          </p>
        </>
      ),
      image: "chat.png",
      btnImage: "chat.svg",
      fade: "fade-up",
    },
    {
      label: "Notifications",
      color: "#FFB851",
      description: (
        <>
          <p>
            <span>Recever</span> des notifications en
            <span> temps réel.</span>
          </p>
        </>
      ),
      image: "notification.png",
      btnImage: "notif.svg",
      fade: "fade-up",
    },
  ];

  return (
    <div class="design-container">
      <div className="design">
        <h1 className="text">
          Avec un design intuitif, ajoutez et cherchez votre annonce plus
          rapidement
        </h1>
      </div>
      <ul id="cards">
        {images.map((image, index) => (
          <Image item={image} index={index} />
        ))}
      </ul>
    </div>
  );
};

export default Design;
