import React, { useContext, useEffect } from "react";
import Landing from "./UI/pages/Landing";
import { Route, Routes } from "react-router-dom";
import Contact from "./UI/pages/Contact";
import About from "./UI/pages/About";
import Copyrights from "./UI/pages/Copyrights";
import Terms from "./UI/pages/Terms";
import Policy from "./UI/pages/Policy";
import Details from "./UI/pages/Details";
import Profile from "./UI/pages/Profile";
import EAgency from "./UI/pages/EAgency";
import Funds from "./UI/pages/Funds";
import Menu from "./UI/components/Menu";
import DownloadModal from "./UI/Utils/DownloadModal";
import { GeneralContext } from "./contexts/General";
import RequestDataDeletion from "./UI/pages/RequestDataDeletion";
import { useSearchParams } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import ReactGA from "react-ga4";
import { DialogContent } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="down"
      in={true}
      // easing={{ enter: "cubic-bezier(0,-1.55,.61,1.58)", exit: "linear" }}
      ref={ref}
      {...props}
    />
  );
});
const Mooshir = () => {
  const { isMenuOpen, setIsModal, isModal } = useContext(GeneralContext);

  useEffect(() => {
    ReactGA.initialize("G-XNT23XWB5P");
    // Send pageview with a custom path
    ReactGA.send({
      hitType: "pageview",
      // page: "/landingpage",
      title: "Landing Page",
    });
  }, []);

  const [searchParams, setSearchParams] = useSearchParams();
  const download = searchParams.get("download");
  useEffect(() => {
    document.getElementsByTagName("html")[0].style.overflowY = isMenuOpen
      ? "hidden"
      : "unset";
  }, [isMenuOpen]);
  return (
    <>
      {/* {(isModal || download !== null) && <DownloadModal />} */}
      <Dialog
        open={isModal || download !== null}
        // TransitionComponent={Transition}

        keepMounted
        fullWidth
        maxWidth="sm"
        transitionDuration={500}
        // onClose={() => (document.body.style.overflow = "unset")}
        aria-describedby="alert-dialog-slide-description"
      >
        <DownloadModal />
      </Dialog>
      {/* {isMenuOpen && <Menu />} */}
      <Dialog
        open={isMenuOpen}
        TransitionComponent={Transition}
        transitionDuration={1000}
        keepMounted
        // sx={{ "&::-webkit-scrollbar": { display: "none" } }}
        // onClose={handleClose}
        // onClose={() => (document.body.style.overflow = "unset")}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <Menu />
        </DialogContent>
      </Dialog>

      <Routes>
        <Route exact path="/" element={<Landing />} />
        <Route
          exact
          path="/getmooshir"
          element={<Landing forceOpenModal={true} />}
        />
        <Route path="/contact" element={<Contact />} />
        <Route
          path="/request-delete-account-details"
          element={<RequestDataDeletion />}
        />
        <Route path="/about" element={<About />} />
        <Route path="/funds" element={<Funds />} />
        <Route path="/copyrights" element={<Copyrights />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/details" element={<Details />} />
        <Route path="/details/:permalink" element={<Details />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/profile/:permalink" element={<Profile />} />
        <Route path="/e-agency" element={<EAgency />} />
        <Route path="/e-agency/:permalink" element={<EAgency />} />
      </Routes>
    </>
  );
};

export default Mooshir;
