import { useState, createContext } from "react";

export const GeneralContext = createContext({});

export const GeneralContextProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const value = {
    // all states and functions
    isLoading,
    setIsLoading,
    isModal,
    setIsModal,
    isMenuOpen,
    setIsMenuOpen,
  };

  return (
    <GeneralContext.Provider value={value}>{children}</GeneralContext.Provider>
  );
};
