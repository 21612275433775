import React from "react";
import "./style.scss";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import AppFooter from "../../components/AppFooter";
// import usePixelFacebook from "../../Utils/Hooks/usePixelFacebook";

const Funds = () => {
  // usePixelFacebook();
  return (
    <>
      <div className="funds">
        <Navbar />
        <div className="content">
          <div className="text">
            <h3>Startup Fund</h3>
            <h1>
              <div>The best investment</div>
              <div>on earth is earth</div>
            </h1>
            <p>
              Join the team & Investing in startups with big vision & innovation
              about Real estate & services sector
            </p>
          </div>

          <div className="image">
            <img src={require("../../../assets/funds.webp")} alt="" />
            <p>
              Join the team & Investing in startups with big vision & innovation
              about Real estate & services sector
            </p>
          </div>
        </div>
        <section>
          <h2>Our Vision</h2>
          <img
            id="vision-image"
            src={require("../../../assets/Mooshir.svg").default}
            alt=""
          />
          <p>
            The denomination is an anglicisation of the arabic name ( مشير ),
            wich means The Wise Advisor. The idea emerged from a desire to solve
            a common problem of Algerian individuals when looking for a real
            estate properties for rental or purchase, the fact is that the
            market is huge but not organised and the offers aren’t well
            displayed to the demands , even by the presence of the real estate
            agencies in the market the Algerian prospector still struggling to
            find the better property that fit with his expectation
          </p>
        </section>

        <section>
          <h2>Our Mission</h2>
          <img
            src={require("../../../assets/funds-mission.svg").default}
            alt=""
          />
          <p>
            We decided to take up the challenge and pushing the boundaries by
            adding a new experience. The Map Listing Displays that is the key
            feature of Mooshir, where the user is taken in spatial journey
            during the search for a real estate products near his location or in
            a precise area, offering the best user experience,
          </p>
        </section>

        <div className="funds-label">
          <div className="label-images">
            <h2>We got Label "STARTUP"</h2>
            <div className="label-1">
              <img
                id="label-image-2"
                src={require("../../../assets/label-2.webp")}
                alt=""
              />
              <img
                id="label-image-1"
                src={require("../../../assets/label-1.webp")}
                alt=""
              />
            </div>

            <div className="label-2">
              <img
                id="label-image-2"
                src={require("../../../assets/label-3.webp")}
                alt="label"
              />
            </div>
          </div>
          <div className="label-text">
            <h2>We got Label "STARTUP"</h2>
            <p>
              The Startup Label is an institutional document that will serve as
              "passport" to access all the facilities that the state makes
              available to startups in algeria & worldwide opportunities during
              5 years.
            </p>
            <h3>
              <a href="https://www.startup.dz">www.startup.dz</a>
            </h3>
          </div>
        </div>

        <div className="contact-cta">
          <h2>Get in touch with Mooshir</h2>
          <button>
            {" "}
            <a href="/contact"> GET IN TOUCH</a>
          </button>
        </div>
      </div>
      <div className="desk-footer">
        <Footer backgroundColor={"#fff"} />
      </div>

      <div className="mobile-footer">
        <AppFooter
          containerColor="#fff"
          backgroundColor={"#e9e9e9"}
          colorBox={"#e9e9e9"}
        />
      </div>
    </>
  );
};

export default Funds;
