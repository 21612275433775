import React, { useContext } from "react";
import "./style.scss";
import { GeneralContext } from "../../../contexts/General";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

const NavbarEmpty = () => {
  const { setIsModal, setIsMenuOpen, isMenuOpen } = useContext(GeneralContext);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className="navbar-container">
      <nav id="navbar">
        <div className="navbar-left"></div>
        <a href="/" className="navbar-center">
          <img
            id="img"
            alt="icon"
            src={require("../../../assets/Mooshir-text.svg").default}
          />
        </a>
        <div className="navbar-right"></div>
      </nav>
    </div>
  );
};

export default NavbarEmpty;
