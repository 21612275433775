import React from "react";
import ReactGA from "react-ga4";
export function CustomEventGA(cat, action, label) {
  console.log(cat, action, label);
  ReactGA.event({
    category: action,
    action: action,
    label: label,
  });
}
