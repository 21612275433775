import React, { useEffect } from "react";
import "./style.scss";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import AppFooter from "../../components/AppFooter";
import { isAndroid, isIOS } from "react-device-detect";
import { useSearchParams, useParams } from "react-router-dom";
// import usePixelFacebook from "../../Utils/Hooks/usePixelFacebook";

const Details = () => {
  // usePixelFacebook();
  const [searchParams, setSearchParams] = useSearchParams();
  const permalinkSearchParams = searchParams.get("permalink");
  let { permalink } = useParams();
  if (!permalink && permalinkSearchParams) {
    permalink = permalinkSearchParams;
  }
  useEffect(() => {
    if (!permalink || !isAndroid || !isIOS) {
      window.location.replace("https://www.mooshir.com?download");
    }
    if (isAndroid) {
      const url = `intent://www.mooshir.com/details?permalink=${permalink}#Intent;scheme=mooshir-app;package=com.mooshir;end`;
      window.location.replace(url);
    } else if (isIOS) {
      // TODO should check redirection on ios
      window.location.replace(
        `mooshir-app://www.mooshir.com/details?permalink=${permalink}`
      );
      setTimeout(() => {
        window.location.replace(
          "https://apps.apple.com/app/mooshir-immobilier-services/id6453938369"
        );
      }, 10000);
    }
  }, []);

  return (
    <div className="details">
      <Navbar />
      <div className="details-text">
        <div>
          Si vous n’avez pas été redirigé automatiquement, veuillez cliquer sur
          le bouton:
        </div>
        {isAndroid ? (
          <>
            <a href="https://play.google.com/store/apps/details?id=com.mooshir">
              Télécharger l’application Mooshir
            </a>
          </>
        ) : isIOS ? (
          <a href="https://apps.apple.com/app/mooshir-immobilier-services/id6453938369">
            Télécharger l’application Mooshir
          </a>
        ) : (
          <>
            <a href="https://www.mooshir.com/?download">www.mooshir.com</a>
          </>
        )}
      </div>

      <div className="desk-footer">
        <Footer />
      </div>

      <div className="mobile-footer">
        <AppFooter containerColor="#EAF7FF" />
      </div>
    </div>
  );
};

export default Details;
