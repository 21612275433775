import React, { useState } from "react";
import axios from "axios";
import "./style.scss";
// import Navbar from "../../components/Navbar";
// import Footer from "../../components/Footer";
// import AppFooter from "../../components/AppFooter";
// import Select from "../../Utils/Select";
import CustomModal from "../../Utils/CustomModal";
import NavbarEmpty from "../../components/NavbarEmpty";
// import usePixelFacebook from "../../Utils/Hooks/usePixelFacebook";

const RequestDataDeletion = () => {
  // usePixelFacebook();
  const initForm = {
    email: "",
    country: "",
    interestedIn: "",
    phone: "",
    email: "",
    message: "",
  };
  const [contactForm, setContactForm] = useState(initForm);
  const mssgCb = {
    valid: "Votre mail est envoyer avec success",
    error: "Votre mail est envoyer avec success",
  };
  const [modalMessage, setModalMessage] = useState(mssgCb.error);
  const [modalOpen, setModalOpen] = useState(false);

  /* const handleSelect = (name, value) => {
    // console.log(" select ", value, name);
    setContactForm({ ...contactForm, [name]: value });
  }; */

  const handleChange = (e) => {
    // console.log(" input ", e.target.name, e.target.value);
    setContactForm({ ...contactForm, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle form submission here
    // console.log("before ", contactForm);
    if (Object.keys(initForm).some((attr) => !contactForm[attr])) {
      return;
    }
    // console.log("after ", contactForm);
    await axios({
      method: "post",
      url: "https://api.mooshir.com/api/v1/customer/account/delete",
      data: { ...contactForm },
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (!!response && !!response?.status === 200) {
        setModalMessage(mssgCb.valid);
      } else {
        setModalMessage(mssgCb.error);
      }
      setModalOpen(true);
    });

    // Reset form fields if needed
    setContactForm(initForm);
  };

  return (
    <div className="contact">
      <NavbarEmpty />
      <div className="contact-content">
        <form onSubmit={handleSubmit} method="POST">
          <div className="form-content">
            <div>
              <img
                className="delete-account-image"
                alt="icon"
                src={require("../../../assets/delete-account.svg").default}
              />
              <h2>Suppression du compte MOOSHIR </h2>
              <p className="contact-p">
                Nous vous remercions sincèrement pour avoir fait partie de notre
                communauté via notre application Mooshir. Votre soutien a été
                précieux et nous vous souhaitons le meilleur.
              </p>
            </div>

            <div id="form-input">
              <div className="input-container">
                <div className="form-content2">
                  <img
                    className="email-image"
                    alt="icon"
                    src={require("../../../assets/email.svg").default}
                  />
                  <div className="row-div">
                    <h4>Email</h4>
                  </div>
                </div>
                <input
                  type="text"
                  placeholder="Adresse mail"
                  name="email"
                  value={contactForm.email}
                  onChange={handleChange}
                  style={{ backgroundColor: "#F7F7F7" }}
                />
                {/* <p className="error-message message-absolute">
                  Ce champ est requis{" "}
                </p> */}
              </div>
              <button
                id="button"
                type="submit"
                // onClick={() => console.log("click on send message")}
              >
                <span id="span">vérifie mon compte</span>
              </button>
              <p className="contact-p2">
                Vous allez recevoir un email de vérification que votre compte a
                été supprimé.
              </p>
            </div>
          </div>
        </form>
      </div>

      <CustomModal message={modalMessage} open={modalOpen} />
    </div>
  );
};

export default RequestDataDeletion;
