import React, { useEffect, useRef, useState } from "react";
import "./style.scss";

import { motion, useScroll, useTransform, useInView } from "framer-motion";
import useWindowDimensions from "../../Utils/getWindowDimensions";

function useParallax(value, distance) {
  return useTransform(value, [1, 0], [distance, -distance]);
}
const ShowCase = (props) => {
  const ref = useRef(null);
  const { height, width } = useWindowDimensions();
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end end"],
  });

  const [isMobile, setIsMobile] = useState(false);
  const [offsetHeight, setOffsetHeight] = useState(null);
  const elem = document.querySelector("#embed");
  useEffect(() => {
    if (elem) {
      setOffsetHeight(elem.getBoundingClientRect().height);
    }
    if (width < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [width]);

  const scale4 = useTransform(scrollYProgress, [0, 1], [0.5, 1]);

  // function playFullscreen() {
  //   var requestFullScreen =
  //     iframe?.requestFullScreen ||
  //     iframe?.mozRequestFullScreen ||
  //     iframe?.webkitRequestFullScreen;
  //   if (requestFullScreen) {
  //     requestFullScreen?.bind(iframe)();
  //   }
  // }

  const [videoFullScreen, setVideoFullScreen] = useState(false);

  return (
    <>
      <div id="show-case" className="show-case">
        <motion.div
          ref={ref}
          className="parent"
          style={{
            scale: isMobile ? undefined : scale4,
          }}
        >
          <div className="title_container_mobile">
            <div className="showCase_title_mobile">
              <h1 id="text">Découvrir les fonctionnalités de Mooshir</h1>
            </div>
          </div>

          <div
            onClick={() => {
              // playFullscreen();
              setVideoFullScreen(true);

              console.log(videoFullScreen);
            }}
            style={{ display: videoFullScreen ? "none" : "flex" }}
            id="showCase_title"
            className="showCase_title"
          >
            <button class="glass-button">
              <img
                className="play-image"
                alt="icon"
                src={require("../../../assets/buttonPlay.svg").default}
              />
            </button>
          </div>
          <div id="embed" class="embed-container">
            {isMobile ? (
              <iframe
                id="embed"
                src={`https://player.vimeo.com/video/995377319?loop=true&autoplay=true&muted=${!videoFullScreen}&controls=${videoFullScreen}`}
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture;"
                webkitAllowFullScreen
                mozallowfullscreen
                allowFullScreen
                // className="video-vimeo"
              ></iframe>
            ) : (
              <iframe
                src={`https://www.youtube.com/embed/akg2_dE3nVY?loop=1&autoplay=1&mute=${
                  videoFullScreen ? 0 : 1
                }&controls=${videoFullScreen ? 1 : 0}`}
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture;"
                webkitAllowFullScreen
                mozallowfullscreen
                allowFullScreen
                // className="video-vimeo"
              ></iframe>
            )}
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default ShowCase;
