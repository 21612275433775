import React, { useContext } from "react";
import "./style.scss";
import { GeneralContext } from "../../../contexts/General";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { CustomEventGA } from "../../Utils/CustomEventGA";
const Navbar = () => {
  const { setIsModal, setIsMenuOpen, isMenuOpen } = useContext(GeneralContext);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const btn = document.querySelector(".btn_download");
  console.log("btn", btn);

  return (
    <div className="navbar-container">
      <nav className="navbar">
        <div className="navbar-left">
          {!isMenuOpen && (
            <img
              onClick={() => setIsMenuOpen(true)}
              height={48}
              alt="icon"
              src={require("../../../assets/Menu-open.svg").default}
            />
          )}
          {isMenuOpen && (
            <img
              className="close-btn-image"
              onClick={() => setIsMenuOpen(false)}
              alt="icon"
              src={require("../../../assets/close.svg").default}
            />
          )}
          <span>Menu</span>
        </div>
        <a href="/" className="navbar-center">
          <img
            alt="icon"
            src={require("../../../assets/Mooshir-text.svg").default}
          />
        </a>

        <div className="navbar-right">
          <a href="/getmooshir">
            <button
              onMouseDown={() => btn.classList.add("pressed")}
              onMouseUp={() => {
                btn.classList.remove("pressed");
              }}
              id="btn_download"
              className="btn_download"
              onClick={() => {
                CustomEventGA({
                  cat: "click-button",
                  action: "download-button-nav-bar",
                  label: "download-button-nav-bar",
                });
                // setIsModal(true);
              }}
            >
              Téléchargez L'application
            </button>
          </a>
          {/* <div className="languages">
            <Typography
              aria-owns={open ? "mouse-over-popover" : undefined}
              aria-haspopup="true"
              onMouseEnter={(event) => {
                handlePopoverOpen(event);

                CustomEventGA({
                  cat: "hover-nav-bar-langages-section",
                  action: "hover-on-arabic-langage",
                  label: "hover-on-arabic-langage",
                });
              }}
              onMouseLeave={handlePopoverClose}
            >
              <option className="language">Ar</option>
            </Typography>
            <Popover
              id="mouse-over-popover"
              sx={{
                pointerEvents: "none",
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <Typography sx={{ p: 1 }}>bientôt disponible</Typography>
            </Popover>
            <Typography
              aria-owns={open ? "mouse-over-popover" : undefined}
              aria-haspopup="true"
              onMouseEnter={(event) => {
                handlePopoverOpen(event);
                CustomEventGA({
                  cat: "hover-nav-bar-langages-section",
                  action: "hover-on-english-langage",
                  label: "hover-on-english-langage",
                });
              }}
              onMouseLeave={handlePopoverClose}
            >
              <option className="language">En</option>
            </Typography>
            <Popover
              id="mouse-over-popover"
              sx={{
                pointerEvents: "none",
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <Typography sx={{ p: 1 }}>bientôt disponible</Typography>
            </Popover>
            <Typography
              aria-owns={open ? "mouse-over-popover" : undefined}
              aria-haspopup="true"
            >
              <option className="language active_language">Fr</option>
            </Typography>
            <Popover
              id="mouse-over-popover"
              sx={{
                pointerEvents: "none",
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <Typography sx={{ p: 1 }}>bientôt disponible</Typography>
            </Popover>
          </div> */}
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
